import { getConfigEnv } from '@/utils/helpers';
import isOnline from 'is-online';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class GptMixin extends Vue {
  get operatorList() {
    return [
      { operatorID: 8, operatorName: 'petronas', appName: 'petronascanada' },
      { operatorID: 7, operatorName: 'taprock', appName: 'taprock' },
      { operatorID: 6, operatorName: 'pdce', appName: 'pdc' },
      { operatorID: 2, operatorName: 'SWN', appName: 'swn' },
      { operatorID: 1, operatorName: 'Extraction', appName: 'civitas' },
      { operatorID: 9, operatorName: 'demo', appName: 'demo' },
      { operatorID: 5, operatorName: 'caerus', appName: 'caerus' },
    ];
  }

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

}
