
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getConfigEnv, getComponent } from '@/utils/helpers';
import DoughnutChart from '@/lib/charts/doughnutChart';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import gptModule from '@/store/modules/gptModule';
import axiosClient from '@/lib/rest/axiosClient';
import { Debounce } from 'vue-debounce-decorator';
import metaDataModule from '@/store/modules/metaDataModule';
import GptMixin from '@/components/gpt/GptMixin';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    DoughnutChart,
    GptClusterOverview: () => getComponent('gpt/GptClusterOverview'),
    GptIssuesOverview: () => getComponent('gpt/GptIssuesOverview'),
  },
})


export default class GptCommentBox extends mixins(GptMixin) {
  @Prop({ type: Object, required: true }) tasq?: any;

  gptTextQuery = '';

  isTyping = false;

  isLoading = false;

  isLoadingComplete = false;

  chartOptions = {
    responsive: true,
    borderRadius: 50,
    maintainAspectRatio: false,
    events: [],
    loaded: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 90,
    tooltips: {
      bodyFontSize: 7,
    },
  };

  chartData = {
    name: 'Tasqs',
    labels: ['Active', 'Completed', 'Yet to start'],
    datasets: [
      {
        name: 'Tasqs',
        labels: 'Tasqs',
        backgroundColor: ['#273e55', '#FFFFFF'],
        hoverBackgroundColor: ['#273e55', '#FFFFFF'],
        borderColor: ['#273e55', '#FFFFFF'],
        hoverBorderColor: '#FFFFFF',
        hoverBorderWidth: 1,
        borderAlign: 'outer',
        data: [13, 8],
        borderWidth: 2,
        borderRadius: 50,
      },
    ],
  };

  setpointRec = {
    title: 'Setpoint Rec',
    color: 'bg-blue020',
    eventKey: 'show-setpoints',
    date: 'Jan 3 - Feb 14',
  };

  fillOutForms = [
    {
      title: 'Plunger Change',
      color: 'bg-red005',
      date: 'Jan 3 - Feb 14',
    },
    {
      title: 'Site Visit',
      color: 'bg-yellow004',
      date: 'Jan 3 - Feb 14',
    },
  ];

  get responses() {
    return [
      {
        title: 'View Similar',
        color: 'bg-red005',
        date: 'Jan 3 - Feb 14',
        eventKey: 'show-view-similar',
        hover: false,
        hidden: !this.seeSimilars.length,
      },
      {
        title: 'Recommendation',
        color: 'bg-yellow004',
        date: 'Jan 3 - Feb 14',
        eventKey: 'show-recommendation',
        hover: false,
        hidden: !this.recommendation.length,
      },
    ];
  }

  get recommendation() {
    return gptModule.recommendations;
  }

  get seeSimilars() {
    return gptModule.seeSimilars;
  }

  recentLastActions: any = [];

  get lastActionsList() {
    if (this.lastActions && this.lastActions.length) {
      return this.lastActions.sort((a, b) => a.dateTime - b.dateTime);
    }
    return this.recentLastActions.sort((a, b) => a.dateTime - b.dateTime);
  }
  get lastActions() {
    const actions = gptModule.lastActions;
    return actions.map((action) => {
      return {
        ...action,
        title: action.type,
        summary: action.summary,
        dateText: this.formatDate(action.date),
        color: this.mapLastActionColor(action.type),
        date: new Date(action.date),
        dateTime: new Date(action.date).getTime(),
        endDate: action.end_date? new Date(action.end_date) : null,
        prePost: Math.round(action.pre_post * 100),
        reason: action.reason,
        // dateText: this.formatDateRange(
        //   action.startDateTime,
        //   action.endDateTime
        // ),
      };
    });
  }

  get isSetpointTasq() {
    return this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE');
  }

  get forms() {
    let forms: any = metaDataModule.formBuilderData;
    return forms.map((form) => ({
      validateForToday: false,
      isCompleteForToday: false,
      ...form,
    }));
  }

  mapLastActionColor(type) {
    if (type === 'On site fix') {
      return `bg-onsitefix`;
    } else if (type === 'Vendor') {
      return `bg-vendor`;
    } else if (type === 'Setpoint Change') {
      return `bg-setpointchange`;
    }else if (type === 'Changepoint') {
      return `bg-setpointchange`;
    } else if (type === 'Waiting on') {
      return `bg-waitingOn`;
    } else if (type === 'Midstream') {
      return `bg-midstream`;
    } else if (type === 'Alarm') {
      return `bg-alarm`;
    }
    return `bg-tasqNeutral300`;
  }

  formatDate(date) {
    const options: any = { month: 'short', day: 'numeric', year: 'numeric' };

    let dateObj = new Date(date);

    let startFormatted = dateObj.toLocaleDateString('en-US', options);

    return `${startFormatted}`;
  }

  isSavingData = false;

  waitingOnOptions: any = [];

  get selectedForms() {
    return tasqFeedbackModule.selectedForms;
  }

  selectActionOption(option) {
    let formExistIndex = -1;
    if (this.selectedForms && this.selectedForms.length) {
      // @ts-ignore
      formExistIndex = this.selectedForms.findIndex(
        (form) => form.id === option.id
      );
    }

    if (formExistIndex > -1) {
      tasqFeedbackModule.removeSelectedFrom(formExistIndex);
    } else {
      tasqFeedbackModule.pushSelectedForm(option);
    }
  }


  get loadingRecentActions(){
    return gptModule.isLoadingActions
  }


  async getGptLastActions() {
    gptModule.setActionsLoading(true);
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );
    const metaData = {
      useAll: false,
      wellMetaData: {
        ...this.operatorName !== 'demo' && { operator_name: operatorDetails['operatorName']},
        ...this.operatorName !== 'demo' && { operator_id: operatorDetails['operatorID']},
        nodeid: this.tasq.wellName,
      },
    };

    gptModule.resetLastActions();

    const response = await axiosClient.post('/ai/actions', {
      query: '',
      stream: false,
      ...metaData,
    });
    console.log('Response for', 'ai/actions first', ':', response.data);

    const { results } = response.data;

    console.log(results);
    const comments = results['comments'];
    const alarms = results['alarms'];
    const changepoints = results['changepoints'];

    let lastActions = [...comments, ...alarms, ...changepoints];

    this.recentLastActions = lastActions.map((action) => {
      return {
        ...action,
        title: action.type,
        summary: action.summary,
        body: action.body,
        dateText: this.formatDate(action.date),
        color: this.mapLastActionColor(action.type),
        prePost: Math.round(action.pre_post * 100),
        date: new Date(action.date),
        endDate: action.end_date? new Date(action.end_date) : null,
        dateTime: new Date(action.date).getTime(),
        reason: action.reason,
        // dateText: this.formatDateRange(
        //   action.startDateTime,
        //   action.endDateTime
        // ),
      };
    });

    gptModule.setActionsLoading(false);
  }


  get overviewHidden(){
    return gptModule.overviewHidden
  }



  async submitComment(){
    if(this.gptTextQuery.length < 2){
      return;
    }
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );
    const metaData = {
      body: this.gptTextQuery,
      wellMetaData: {
        ...this.operatorName !== 'demo' && { operator_name: operatorDetails['operatorName']},
        ...this.operatorName !== 'demo' && { operator_id: operatorDetails['operatorID']},
        nodeid: this.tasq.wellName,
      },
    };
    this.gptTextQuery = ''
    this.$emit('next-handler',metaData)
  }

  @Debounce(1000)
  async postGptComment(comment) {
    this.isLoadingComplete = false;
    if (!comment.length) {
      this.isTyping = false;
      return;
    }
    this.isTyping = true;

    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );



    const endpoints = [
      {
        url: '/ai/similar',
        stream: false,
        key: 'SeeSimilars',
        useMetadata: true,
        canRemoveOperator: true,
      },
      {
        url: '/ai/recommendations',
        stream: false,
        key: 'Recommendations',
        useMetadata: true,
        canRemoveOperator: true,
      },
      { url: '/ai/forms', stream: false, key: 'Forms', useMetadata: true, canRemoveOperator: false, },
      {
        url: '/ai/actions',
        stream: false,
        key: 'LastActions',
        useMetadata: true,
        canRemoveOperator: true,
      },
    ];

    let completedRequests = 0;
    tasqFeedbackModule.resetAllData();

    // Start all promises (backend requests) but don't wait for them to complete
    // Each request will set its value immediately upon completion
    endpoints.forEach(async (endpoint) => {
      try {
        gptModule[`reset${endpoint.key}`]([]);

        const metaData = {
        useAll: false,
        ...(this.operatorName === 'demo' || endpoint.key === 'SeeSimilar' ) && { use_clean: true},
        wellMetaData: {
          ...(this.operatorName !== 'demo' || !endpoint.canRemoveOperator) && { operator_name: operatorDetails['operatorName']},
          ...(this.operatorName !== 'demo' || !endpoint.canRemoveOperator ) && { operator_id: operatorDetails['operatorID']},
          nodeid: this.tasq.wellName,
        },
      };


        const response = await axiosClient.post(endpoint.url, {
          query: comment,
          stream: endpoint.stream,

          ...(endpoint.useMetadata && {
            ...metaData,
          }),
        });
        console.log('Response for', endpoint.url, ':', response.data);
        if (endpoint.key == 'Forms') {
          if (response.data && response.data.timescaleForms) {
            const form = this.forms.find(
              (f) => f.id === response.data.timescaleForms.id
            );
            tasqFeedbackModule.setSeletedForms([form]);
            tasqFeedbackModule.setSeletedFormsFields(response.data.forms);
          }
        }

        gptModule[`set${endpoint.key}`](response.data);

        completedRequests++;
      } catch (error) {
        completedRequests++;
        console.error('Error fetching data for endpoint:', endpoint.url, error);
      } finally {
        if (completedRequests === endpoints.length) {
          gptModule.setForms(this.forms[0]);
          this.isLoading = false;
          this.isTyping = false;
          this.isLoadingComplete = true;
        }
      }
    });
  }




  async mounted() {
    this.getGptLastActions()
      .then(() => {
      })
      .catch(() => {
        gptModule.setActionsLoading(false);
      });
  }
}
